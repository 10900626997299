<!-- eslint-disable no-nested-ternary -->
<template>
  <li v-if="shouldRenderMessage" :class="alignBubble">
    <div v-if="!isInteractive" :class="wrapClass">
      <div v-if="isIncoming" class="sender--info">
        <woot-thumbnail
          :src="sender.thumbnail"
          :username="senderNameForAvatar"
          size="32px"
        />
        <a
          v-if="isATweet && isIncoming"
          class="sender--available-name"
          :href="twitterProfileLink"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          {{ sender.name }}
        </a>
      </div>
      <div v-if="isFailed" class="message-failed--alert">
        <woot-button
          v-tooltip.top-end="$t('CONVERSATION.TRY_AGAIN')"
          size="tiny"
          color-scheme="alert"
          variant="clear"
          icon="arrow-clockwise"
          @click="retrySendMessage"
        />
      </div>
      <div
        :id="'conversation-' + id"
        v-tooltip.top-start="messageToolTip"
        :class="bubbleClass"
      >
        <bubble-call
          v-if="isCallMessage"
          :message-id="id"
          :conversation-id="conversationId"
          :content-attributes="contentAttributes"
          :message-content-type="data.content_type"
          :message-type="data.message_type"
          :attachments="data.attachments"
          :inbox-id="data.inbox_id.toString()"
        />
        <bubble-integration
          :message-id="data.id"
          :content-attributes="contentAttributes"
          :inbox-id="data.inbox_id"
        />
        <div
          class="wrapper__d-flex-column"
          :style="{ 'padding-bottom': contentWrapperPadding }"
        >
          <bubble-mail-head
            :email-attributes="contentAttributes.email"
            :cc="emailHeadAttributes.cc"
            :bcc="emailHeadAttributes.bcc"
            :is-incoming="isIncoming"
          />
          <bubble-reply-to
            v-if="inReplyToMessageId && inboxSupportsReplyTo.incoming"
            :message="inReplyTo"
            :message-type="data.message_type"
            :parent-has-attachments="hasAttachments"
          />
          <span
            v-if="isPending && hasAttachments"
            class="chat-bubble has-attachment agent"
          >
            {{ $t('CONVERSATION.UPLOADING_ATTACHMENTS') }}
          </span>
          <div
            v-if="!isPending && hasAttachments && !isCallMessage"
            class="wrapper__d-flex-column"
          >
            <div v-for="attachment in data.attachments" :key="attachment.id">
              <bubble-image
                v-if="attachment.file_type === 'image' && !hasImageError"
                :id="id"
                :url="attachment.data_url"
                @error="onImageLoadError"
                @load="onLoaded"
              />
              <div
                v-else-if="attachment.file_type === 'audio'"
                class="audio-section"
                style="min-width: 300px;"
              >
                <div class="message-text__wrap">
                  <custom-audio
                    :message-id="id"
                    :source="attachment.data_url"
                  />
                </div>
              </div>
              <bubble-video
                v-else-if="attachment.file_type === 'video'"
                :id="id"
                :url="attachment.data_url"
                :is-private="data.private"
                :message-type="data.message_type"
                :message-status="status"
                :source-id="data.source_id"
                :inbox-id="data.inbox_id"
                :created-at="createdAt"
              />
              <bubble-location
                v-else-if="attachment.file_type === 'location'"
                :latitude="attachment.coordinates_lat"
                :longitude="attachment.coordinates_long"
                :name="attachment.fallback_title"
              />
              <bubble-contact
                v-else-if="attachment.file_type === 'contact'"
                :name="data.content"
                :phone-number="attachment.fallback_title"
              />
              <instagram-image-error-placeholder
                v-else-if="hasImageError && hasInstagramStory"
              />
              <bubble-fallback
                v-else-if="
                  attachment.file_type === 'fallback' && message === ''
                "
                :message="attachment.fallback_title"
                :url="attachment.data_url"
                :channel-type="channelType"
                :has-instagram-story="hasInstagramStory"
              />
              <bubble-file
                v-else-if="attachment.file_type !== 'fallback'"
                :id="id"
                :url="attachment.data_url"
                @error="onImageLoadError"
                @load="onLoaded"
              />
            </div>
          </div>
          <bubble-text
            v-if="
              (data.content && !hasItemsInContentAttributes) ||
                isAnUnsupportedMessage
            "
            :is-a-whatsapp-channel="isAWhatsappChannel"
            :message="message"
            :reply-to="isReply"
            :is-email="isEmailContentType"
            :display-quoted-button="displayQuotedButton"
          />
        </div>
        <bubble-actions
          :id="data.id"
          :sender="data.sender"
          :story-sender="storySender"
          :story-id="storyId"
          :is-a-tweet="isATweet"
          :is-a-whatsapp-channel="isAWhatsappChannel"
          :has-instagram-story="hasInstagramStory"
          :is-email="isEmailContentType"
          :is-private="data.private"
          :message-type="data.message_type"
          :message-status="status"
          :source-id="data.source_id"
          :inbox-id="data.inbox_id"
          :created-at="createdAt"
        />
      </div>
      <woot-modal
        v-if="showTranslateModal"
        modal-type="right-aligned"
        show
        :on-close="onCloseTranslateModal"
      >
        <div class="column content">
          <p>
            <b>{{ $t('TRANSLATE_MODAL.ORIGINAL_CONTENT') }}</b>
          </p>
          <p v-dompurify-html="data.content" />
          <br />
          <hr />
          <div v-if="translationsAvailable">
            <p>
              <b>{{ $t('TRANSLATE_MODAL.TRANSLATED_CONTENT') }}</b>
            </p>
            <div
              v-for="(translation, language) in translations"
              :key="language"
            >
              <p>
                <strong>{{ language }}:</strong>
              </p>
              <p v-dompurify-html="translation" />
              <br />
            </div>
          </div>
          <p v-else>
            {{ $t('TRANSLATE_MODAL.NO_TRANSLATIONS_AVAILABLE') }}
          </p>
        </div>
      </woot-modal>
      <spinner v-if="isPending" size="tiny" />
      <div
        v-if="showAvatar"
        v-tooltip.left="tooltipForSender"
        class="sender--info"
      >
        <woot-thumbnail
          :src="sender.thumbnail"
          :username="senderNameForAvatar"
          size="32px"
        />
        <a
          v-if="isATweet && isIncoming"
          class="sender--available-name"
          :href="twitterProfileLink"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          {{ sender.name }}
        </a>
      </div>
    </div>
    <div v-else :class="wrapClass">
      <div class="interactive-buttons-container">
        <div
          :id="'conversation-' + id"
          v-tooltip.top-start="messageToolTip"
          :class="bubbleClass"
        >
          <div
            class="wrapper__d-flex-column"
            :style="{ 'padding-bottom': contentWrapperPadding }"
          >
            <div class="message-text__wrap">
              <div v-dompurify-html="message" class="text-content" />
            </div>
          </div>
          <bubble-actions
            :id="data.id"
            :sender="data.sender"
            :story-sender="storySender"
            :story-id="storyId"
            :is-a-tweet="isATweet"
            :is-a-whatsapp-channel="isAWhatsappChannel"
            :has-instagram-story="hasInstagramStory"
            :is-email="isEmailContentType"
            :is-private="data.private"
            :message-type="data.message_type"
            :message-status="status"
            :source-id="data.source_id"
            :inbox-id="data.inbox_id"
            :created-at="createdAt"
          />
        </div>
        <bubble-interactive-buttons
          v-if="isInteractiveButtons"
          :message="message"
          :content-attributes="contentAttributes"
        />
        <bubble-interactive-list
          v-else-if="isInteractiveList"
          :message="message"
          :content-attributes="contentAttributes"
        />
      </div>
      <div
        v-if="showAvatar"
        v-tooltip.left="tooltipForSender"
        class="sender--info"
      >
        <woot-thumbnail
          :src="sender.thumbnail"
          :username="senderNameForAvatar"
          size="32px"
        />
      </div>
    </div>
    <div v-if="shouldShowContextMenu" class="context-menu-wrap">
      <div v-if="hasText || isApplyContentMenu">
        <context-menu
          v-if="isBubble && !isMessageDeleted"
          :is-open="showContextMenu"
          :show-copy="hasText"
          :show-reply-to="showReplyTo"
          :show-delete="hasText || hasAttachments"
          :show-canned-response-option="isOutgoing && hasText"
          :menu-position="contextMenuPosition"
          :message-content="data.content"
          @toggle="handleContextMenuClick"
          @delete="handleDelete"
          @translate="handleTranslate"
        />
      </div>
    </div>
  </li>
</template>
<script>
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import BubbleActions from './bubble/Actions';
import BubbleFile from './bubble/File';
import BubbleImage from './bubble/Image';
import BubbleIntegration from './bubble/Integration.vue';
import BubbleLocation from './bubble/Location';
import BubbleMailHead from './bubble/MailHead';
import BubbleText from './bubble/Text';
import BubbleVideo from './bubble/Video.vue';
import BubbleContact from './bubble/Contact';
import BubbleCall from './bubble/Call';
import BubbleFallback from './bubble/Fallback';
import BubbleReplyTo from './bubble/ReplyTo.vue';
import BubbleInteractiveButtons from './bubble/InteractiveButtons.vue';
import BubbleInteractiveList from './bubble/InteractiveList.vue';
import Spinner from 'shared/components/Spinner';
import ContextMenu from 'dashboard/modules/conversations/components/MessageContextMenu';
import instagramImageErrorPlaceholder from './instagramImageErrorPlaceholder.vue';
import alertMixin from 'shared/mixins/alertMixin';
import contentTypeMixin from 'shared/mixins/contentTypeMixin';
import { MESSAGE_TYPE, MESSAGE_STATUS } from 'shared/constants/messages';
import { generateBotMessageContent } from './helpers/botMessageContentHelper';
import { mapGetters } from 'vuex';
import { getUrlExtension } from '../../../../shared/constants/messages';
import { CALL_TYPES } from 'widget/helpers/constants';
import CustomAudio from './CustomAudio.vue';

export default {
  components: {
    BubbleActions,
    BubbleFile,
    BubbleImage,
    BubbleIntegration,
    BubbleLocation,
    BubbleMailHead,
    BubbleText,
    BubbleVideo,
    BubbleContact,
    BubbleCall,
    BubbleFallback,
    ContextMenu,
    Spinner,
    instagramImageErrorPlaceholder,
    BubbleReplyTo,
    BubbleInteractiveButtons,
    BubbleInteractiveList,
    CustomAudio,
  },
  mixins: [alertMixin, messageFormatterMixin, contentTypeMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    createdAtBubble: {
      type: String,
      default: '',
    },
    isATweet: {
      type: Boolean,
      default: false,
    },
    isAWhatsappChannel: {
      type: Boolean,
      default: false,
    },
    hasInstagramStory: {
      type: Boolean,
      default: false,
    },
    isWebWidgetInbox: {
      type: Boolean,
      default: false,
    },
    channelType: {
      type: String,
      default: '',
    },
    inboxSupportsReplyTo: {
      type: Object,
      default: () => ({}),
    },
    inReplyTo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showContextMenu: false,
      hasImageError: false,
      showTranslateModal: false,
      id: this.data.id.toString(),
      conversationId: this.data.conversation_id.toString(),
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
    }),
    /**
     * Comprueba si el mensaje actual es una respuesta a otro mensaje.
     * @returns {boolean} - true si el mensaje es una respuesta, false de lo contrario.
     */
    isReply() {
      // Comprueba si existe un ID en el mensaje al que se está respondiendo.
      return !!this.data.content_attributes?.in_reply_to;
    },

    /**
     * Determina si se debe mostrar la opción de responder.
     * La opción de responder solo se muestra si la burbuja no es privada y es un canal de WhatsApp.
     *
     * @returns {boolean} `true` si no es privada y es un canal de WhatsApp, de lo contrario `false`.
     */
    showReplyTo() {
      return !this.data.private && this.isAWhatsappChannel;
    },

    /**
     * Determina si hay elementos en el atributo de contenido.
     * @returns {boolean} - Verdadero si hay elementos en el atributo de contenido, falso en caso contrario.
     */
    hasItemsInContentAttributes() {
      return (
        this.contentAttributes.items && this.contentAttributes.items.length > 0
      );
    },
    /**
     * Determina si es un mensaje interactivo
     */
    isInteractive() {
      return this.contentAttributes.items;
    },
    /**
     * Determina si es un mensaje interactivo de botones
     */
    isInteractiveButtons() {
      return (
        this.contentAttributes.items && this.contentAttributes.items.length <= 3
      );
    },
    /**
     * Determina si es un mensaje interactivo de Lista
     */
    isInteractiveList() {
      return (
        this.contentAttributes.items && this.contentAttributes.items.length > 3
      );
    },
    /**
     * Determina si se debe renderizar el mensaje actual basado en varias condiciones:
     * - Si el mensaje tiene archivos adjuntos.
     * - Si el mensaje tiene contenido de texto.
     * - Si el mensaje es de tipo correo electrónico.
     * - Si el mensaje es de tipo integración con otra plataforma.
     * - Si el mensaje es de tipo llamada (ya sea llamada perdida o llamada realizada).
     *
     * @returns {boolean} `true` si se debe renderizar el mensaje, de lo contrario, devuelve `false`.
     */
    shouldRenderMessage() {
      return (
        this.hasAttachments ||
        this.data.content ||
        this.isEmailContentType ||
        this.isAnIntegrationMessage ||
        this.isCallMessage ||
        this.hasItemsInContentAttributes ||
        this.isAnUnsupportedMessage
      );
    },
    currentAgentName() {
      return this.$store.getters.getCurrentUser?.name;
    },
    emailMessageContent() {
      const {
        html_content: { full: fullHTMLContent } = {},
        text_content: { full: fullTextContent } = {},
      } = this.contentAttributes.email || {};
      return fullHTMLContent || fullTextContent || '';
    },
    translations() {
      return this.contentAttributes.translations || {};
    },
    displayQuotedButton() {
      if (!this.isIncoming) {
        return false;
      }

      if (this.emailMessageContent.includes('<blockquote')) {
        return true;
      }

      return false;
    },
    translationsAvailable() {
      return !!Object.keys(this.translations).length;
    },
    contentWrapperPadding() {
      return (this.hasText &&
        (this.hasMediaAttachment('video') ||
          this.hasMediaAttachment('image'))) ||
        this.hasText
        ? '18px'
        : (this.hasMediaAttachment('video') &&
            getUrlExtension(this.data?.attachments[0]?.data_url) !== 'ogx') ||
          this.hasMediaAttachment('image')
        ? 0
        : this.hasAttachments || this.isCallMessage
        ? '24px'
        : 0;
    },
    /**
     * Obtiene el ID del mensaje al que se está respondiendo, si está presente.
     * @returns {string | undefined} - El ID del mensaje al que se está respondiendo, o undefined si no está presente.
     */
    inReplyToMessageId() {
      return this.data.content_attributes?.in_reply_to;
    },
    message() {
      if (this.contentType === 'input_csat') {
        return this.$t('CONVERSATION.CSAT_REPLY_MESSAGE');
      }

      // If the message is an email, emailMessageContent would be present
      // In that case, we would use letter package to render the email
      if (this.emailMessageContent && this.isIncoming) {
        return this.emailMessageContent;
      }

      const messageContent =
        // eslint-disable-next-line no-nested-ternary
        this.isAnUnsupportedMessage && !this.isPending
          ? this.hasInstagramStory
            ? this.$t('CONVERSATION.NO_SUPPORTED_MESSAGE', {
                channel: 'Instagram',
              })
            : this.$t('CHAT_LIST.NO_CONTENT')
          : this.data.content;

      const botMessageContent = generateBotMessageContent(
        this.contentType,
        this.contentAttributes,
        {
          noResponseText: this.$t('CONVERSATION.NO_RESPONSE'),
          csat: {
            ratingTitle: this.$t('CONVERSATION.RATING_TITLE'),
            feedbackTitle: this.$t('CONVERSATION.FEEDBACK_TITLE'),
          },
        }
      );
      return (
        this.formatMessage(
          messageContent,
          this.isATweet,
          this.data.private,
          this.isAWhatsappChannel
        ) + botMessageContent
      );
    },
    contentAttributes() {
      return this.data.content_attributes || {};
    },
    sender() {
      return this.data.sender || {};
    },
    status() {
      return this.data.status;
    },
    storySender() {
      return this.contentAttributes.story_sender || null;
    },
    storyId() {
      return this.contentAttributes.story_id || null;
    },
    contentType() {
      const { content_type: contentType } = this.data;
      return contentType;
    },
    twitterProfileLink() {
      const additionalAttributes = this.sender.additional_attributes || {};
      const { screen_name: screenName } = additionalAttributes;
      return `https://twitter.com/${screenName}`;
    },
    alignBubble() {
      const { message_type: messageType } = this.data;
      const isCentered = messageType === MESSAGE_TYPE.ACTIVITY;
      const isLeftAligned = messageType === MESSAGE_TYPE.INCOMING;
      const isRightAligned =
        messageType === MESSAGE_TYPE.OUTGOING ||
        messageType === MESSAGE_TYPE.TEMPLATE;

      return {
        center: isCentered,
        left: isLeftAligned,
        right: isRightAligned,
        'has-context-menu': this.showContextMenu,
        'has-tweet-menu': this.isATweet,
      };
    },
    createdAt() {
      return this.contentAttributes.external_created_at || this.data.created_at;
    },
    isBubble() {
      return [0, 1, 3].includes(this.data.message_type);
    },
    isIncoming() {
      return this.data.message_type === MESSAGE_TYPE.INCOMING;
    },
    isOutgoing() {
      return this.data.message_type === MESSAGE_TYPE.OUTGOING;
    },
    isTemplate() {
      return this.data.message_type === MESSAGE_TYPE.TEMPLATE;
    },
    isAnIntegrationMessage() {
      return this.contentType === 'integrations';
    },
    // Trata el mensaje como no soportado cuando llega sin contenido para mostrar y no es de un canal de IntertelUC
    isAnUnsupportedMessage() {
      return (
        !this.isCallMessage &&
        !this.hasAttachments &&
        this.data.content === null
      );
    },
    /**
     * Comprueba si el mensaje actual es de tipo llamada (ya sea llamada perdida o llamada realizada).
     *
     * @returns {boolean} `true` si el mensaje es de tipo llamada, de lo contrario, devuelve `false`.
     */
    isCallMessage() {
      return CALL_TYPES.includes(this.contentType);
    },
    emailHeadAttributes() {
      return {
        email: this.contentAttributes.email,
        cc: this.contentAttributes.cc_emails,
        bcc: this.contentAttributes.bcc_emails,
      };
    },
    hasAttachments() {
      return !!(this.data.attachments && this.data.attachments.length > 0);
    },
    hasExtensionFile() {
      return this.hasAttachments
        ? getUrlExtension(this.data.attachments[0].data_url)
        : null;
    },
    isApplyContentMenu() {
      return !!(
        this.hasAttachments &&
        ['location', 'contact'].includes(this.data.attachments[0].file_type)
      );
    },
    isMessageDeleted() {
      return this.contentAttributes.deleted;
    },
    hasText() {
      /* 
      Se utilizó esta comparación en el método con el fin de garantizar que se agrege la clase CSS "is-text" a la burbuja de mensaje en caso de que sea
      un mensaje no soportado. Este comportamiento es necesario porque en caso de ser un mensaje no soportado se debe mostrar un texto informativo en la burbuja
      y para que guarde la distancia con la fecha que se muesta al final de la burbuja es necesario que tenga aplicada la clase CSS "is-text" 
      */
      return !!this.data.content || this.isAnUnsupportedMessage;
    },
    tooltipForSender() {
      const name = this.senderNameForAvatar;
      const { message_type: messageType } = this.data;
      const showTooltip =
        messageType === MESSAGE_TYPE.OUTGOING ||
        messageType === MESSAGE_TYPE.TEMPLATE;
      return showTooltip
        ? {
            content: `${this.$t('CONVERSATION.SENT_BY')} ${name}`,
          }
        : false;
    },
    messageToolTip() {
      if (this.isMessageDeleted) {
        return false;
      }
      if (this.isFailed) {
        return this.$t(`CONVERSATION.SEND_FAILED`);
      }
      return false;
    },
    /**
     * Devuelve un objeto que representa las clases CSS que se aplicarán al elemento de la conversación actual.
     * @returns {Object} Objeto con las clases CSS a aplicar.
     * - Si `isBubble` es verdadero, incluirá la clase "wrap".
     * - Si `isBubble` es falso, incluirá la clase "activity-wrap".
     * - Si `isPending` es verdadero, incluirá la clase "is-pending".
     * - Si `isFailed` es verdadero, incluirá la clase "is-failed".
     * - Si `isEmailContentType` es verdadero, incluirá la clase "is-email".
     * - Si `isCallMessage` es verdadero, incluirá la clase "is-call".
     */
    wrapClass() {
      return {
        wrap: this.isBubble,
        'activity-wrap': !this.isBubble,
        'is-pending': this.isPending,
        'is-failed': this.isFailed,
        'is-email': this.isEmailContentType,
        'is-call': this.isCallMessage,
      };
    },
    /**
     * Devuelve un objeto que representa las clases CSS que se aplicarán a la burbuja del mensaje actual.
     * @returns {Object} Objeto con las clases CSS a aplicar.
     * - Si `isBubble` es verdadero, incluirá la clase "bubble".
     * - Si `data.private` es verdadero, incluirá la clase "is-private".
     * - Si tiene un archivo adjunto de imagen, incluirá la clase "is-image".
     * - Si tiene un archivo adjunto de video, incluirá la clase "is-video".
     * - Si tiene un archivo adjunto de audio, incluirá la clase "is-audio".
     * - Si `hasText` es verdadero, incluirá la clase "is-text".
     * - Si `isSentByBot` es verdadero, incluirá la clase "is-from-bot".
     * - Si `isFailed` es verdadero, incluirá la clase "is-failed".
     * - Si `isEmailContentType` es verdadero, incluirá la clase "is-email".
     * - Si `isCallMessage` es verdadero, incluirá la clase "is-call".
     */
    bubbleClass() {
      return {
        bubble: this.isBubble,
        'is-private': this.data.private,
        'is-image': this.hasMediaAttachment('image'),
        'is-video': this.hasMediaAttachment('video'),
        'is-audio': this.hasMediaAttachment('audio'),
        'is-text': this.hasText,
        'is-from-bot': this.isSentByBot,
        'is-failed': this.isFailed,
        'is-email': this.isEmailContentType,
        'is-call': this.isCallMessage,
        'is-response': this.inReplyToMessageId,
      };
    },
    isPending() {
      return this.data.status === MESSAGE_STATUS.PROGRESS;
    },
    isFailed() {
      return this.data.status === MESSAGE_STATUS.FAILED;
    },
    isSentByBot() {
      if (this.isPending || this.isFailed) return false;
      return !this.sender.type || this.sender.type === 'agent_bot';
    },
    contextMenuPosition() {
      const { message_type: messageType } = this.data;
      return messageType ? 'right' : 'left';
    },
    shouldShowContextMenu() {
      return !(this.isFailed || this.isPending);
    },
    errorMessage() {
      const { meta } = this.data;
      return meta ? meta.error : '';
    },
    showAvatar() {
      if (this.isOutgoing || this.isTemplate) {
        return true;
      }
      return this.isATweet && this.isIncoming && this.sender;
    },
    senderNameForAvatar() {
      if (this.isOutgoing || this.isTemplate) {
        /* Coloca el prefijo Bot_ en el nombre si es un bot externo */
        let { name } = this.sender || {};

        if (this.isSentByBot) {
          name = name
            ? `${this.$t('CONVERSATION.BOT')}_${name}`
            : this.$t('CONVERSATION.BOT');
        }
        return name;
      }

      const { name = '' } = this.sender || {};
      return name;
    },
  },
  watch: {
    data() {
      this.convertFilesToAttachments();
      this.hasImageError = false;
    },
  },
  mounted() {
    this.hasImageError = false;
  },
  methods: {
    /**
     * Convierte `this.data.files` en `this.data.attachments` con los campos necesarios.
     * No realiza ninguna acción si `this.data.files` no existe.
     *
     * @method
     * @returns {void}
     *
     * @property {Object[]} [this.data.files] - Archivos a convertir.
     * @property {Object[]} [this.data.attachments] - Attachments resultantes.
     */
    convertFilesToAttachments() {
      if (this.data.files) {
        this.data.attachments = this.data.files.map(file => ({
          id: file.id || null,
          message_id: this.data.id || null,
          file_type: file.type,
          account_id: null,
          extension: file.name.split('.').pop(),
          data_url: URL.createObjectURL(file),
          thumb_url: URL.createObjectURL(file),
          file_size: file.size,
        }));
      }
    },
    /**
     * Método emitir evento al componente padre avisando que el atachment se ha cargado
     */
    onLoaded() {
      this.$emit('load');
    },
    hasMediaAttachment(type) {
      if (this.hasAttachments) {
        // para canales de instagram
        if (
          this.hasInstagramStory &&
          this.data.attachments[0].file_type === 'share'
        ) {
          const images = ['JPEG', 'JPG', 'GIF', 'PNG'];
          const videos = ['MP4', 'GIF', 'MOV'];
          const extension = getUrlExtension(
            this.data.attachments[0].data_url
          ).toUpperCase();
          images.includes(extension)
            ? (this.data.attachments[0].file_type = 'image')
            : videos.includes(extension)
            ? (this.data.attachments[0].file_type = 'video')
            : null;
        }
        const existType = this.data.attachments.find(attachment => {
          const { file_type: fileType } = attachment;
          return fileType === type;
        });
        return existType && !this.hasImageError;
      }
      return false;
    },
    handleContextMenuClick() {
      this.showContextMenu = !this.showContextMenu;
    },
    async handleDelete() {
      const { conversation_id: conversationId, id: messageId } = this.data;
      try {
        await this.$store.dispatch('deleteMessage', {
          conversationId,
          messageId,
        });
        this.showAlert(this.$t('CONVERSATION.SUCCESS_DELETE_MESSAGE'));
        this.showContextMenu = false;
      } catch (error) {
        this.showAlert(this.$t('CONVERSATION.FAIL_DELETE_MESSSAGE'));
      }
    },
    async retrySendMessage() {
      await this.$store.dispatch('sendMessageWithData', this.data);
    },
    onImageLoadError() {
      this.hasImageError = true;
    },
    handleTranslate() {
      const { locale } = this.getAccount(this.currentAccountId);
      const { conversation_id: conversationId, id: messageId } = this.data;
      this.$store.dispatch('translateMessage', {
        conversationId,
        messageId,
        targetLanguage: locale || 'en',
      });
      this.showTranslateModal = true;
    },
    onCloseTranslateModal() {
      this.showTranslateModal = false;
    },
  },
};
</script>
<style lang="scss">
.conversation-panel > li.right .bubble.is-call {
  display: grid;
  padding: 12px 16px;
  background: var(--w-50);
  border-radius: 0.8rem;
  border: 1px var(--conversa2-blue-200-color) solid;

  .message-text--metadata .time {
    color: var(--conversa2-neutral-500-color);
    font-size: 1rem;
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: 1.8rem;
  }
}

.conversation-panel > li.right .bubble.is-text.is-from-bot {
  background: var(--conversa2-blue-200-color);
  border: 1px solid var(--conversa2-blue-300-color);
  color: var(--conversa2-neutral-900-color);
  line-height: var(--space-two);

  .message-text--metadata .time {
    color: var(--conversa2-neutral-600-color);
  }
  &.is-private .message-text--metadata .time {
    color: var(--s-400);
  }

  .message-text__wrap .text-content .link {
    color: var(--conversa2-neutral-800-color);
    text-decoration: none;
  }
  .message-text__wrap .text-content .link:hover {
    color: var(--conversa2-neutral-800-color);
    text-decoration: underline;
  }
}

.conversation-panel > li.left .bubble.bubble.is-call {
  display: grid;
  padding: 12px 16px;
  background: var(--white);
  border-radius: 0.8rem;
  border: 1px var(--conversa2-neutral-100-color) solid;

  .message-text--metadata .time {
    color: var(--conversa2-neutral-400-color);
    font-size: 1rem;
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: 1.8rem;
  }
}

.wrapper__download {
  border-radius: var(--space-smaller);
  padding: var(--space-smaller);
}

.wrapper__d-flex-column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.file.message-text__wrap {
  display: flex;
  padding: var(--space-small);
  flex-direction: row;
  align-items: center;
  gap: var(--space-one);
  border-radius: var(--space-smaller);
}

li.right .wrap {
  .download.button:hover {
    background-color: rgba(183, 227, 246, 0.6);
  }

  .file.message-text__wrap {
    background-color: var(--conversa2-blue-100-color);
  }

  .is-private {
    .file.message-text__wrap {
      background-color: var(--conversa2-orange-50-color);
    }
    .download.button:hover {
      background-color: var(--conversa2-orange-100-color);
    }
  }

  .message-text__wrap .text-content .link {
    text-decoration: none;
  }

  .message-text__wrap .text-content .link:hover {
    text-decoration: underline;
  }
}

li.left .wrap {
  .download.button:hover {
    background-color: var(--conversa2-neutral-100-color);
  }

  .file.message-text__wrap {
    background-color: var(--conversa2-neutral-50-color);
  }
}

.wrap {
  > .bubble {
    min-width: 128px;

    &.is-image,
    &.is-video {
      overflow: hidden;

      .image,
      .video {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 32rem;
        border-radius: var(--border-radius-normal-small);
        overflow: hidden;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.24);

        > video {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
      .video {
        height: 18rem;
        min-width: 18rem;
      }
    }

    &.is-image.is-text > .wrapper__d-flex-column > .message-text__wrap {
      max-width: 32rem;
    }

    &.is-video.is-text > .wrapper__d-flex-column > .message-text__wrap {
      max-width: 32rem;
    }

    &.is-audio.is-text > .wrapper__d-flex-column > .message-text__wrap {
      max-width: 32rem;
    }

    &.is-private {
      .file--icon {
        color: var(--w-400);
      }
      .text-block-title {
        color: var(--color-body);
      }
    }

    &.is-private.is-text > .wrapper__d-flex-column > .message-text__wrap .link {
      color: var(--conversa2-neutral-800-color);
      text-decoration: none;
    }

    &.is-private.is-text
      > .wrapper__d-flex-column
      > .message-text__wrap
      .link:hover {
      color: var(--conversa2-neutral-800-color);
      text-decoration: underline;
    }
    &.is-private.is-text
      > .wrapper__d-flex-column
      > .message-text__wrap
      .prosemirror-mention-node {
      font-weight: var(--font-weight-black);
      background: none;
      border-radius: var(--border-radius-small);
      padding: 0;
      color: var(--color-body);
      text-decoration: underline;
    }

    &.is-failed {
      background: var(--r-200);

      .message-text--metadata .time {
        color: var(--r-50);
      }
    }
  }

  &.is-pending {
    position: relative;
    opacity: 0.8;

    .spinner {
      position: absolute;
      bottom: var(--space-smaller);
      right: var(--space-smaller);
    }

    > .is-image.is-text.bubble > .message-text__wrap {
      padding: 0;
    }
  }
}

.wrap.is-email {
  --bubble-max-width: 84% !important;
}

.sender--info {
  align-items: center;
  color: var(--b-700);
  display: inline-flex;
  padding: var(--space-smaller) 0;

  .sender--available-name {
    font-size: var(--font-size-mini);
    margin-left: var(--space-smaller);
  }
}

.message-failed--alert {
  color: var(--r-900);
  flex-grow: 1;
  text-align: right;
  margin-top: var(--space-smaller) var(--space-smaller) 0 0;
}

.button--delete-message {
  visibility: hidden;
}

li.left,
li.right {
  display: flex;
  align-items: flex-start;

  .context-menu {
    display: none;
  }

  &.has-context-menu .context-menu {
    display: block;
  }

  &:hover {
    &.button--delete-message {
      visibility: visible;
    }
    .context-menu {
      display: block;
    }
  }
}

li.left.has-tweet-menu .context-menu {
  margin-bottom: var(--space-medium);
}

li.right .context-menu-wrap {
  margin-left: auto;
}

li.right {
  flex-direction: row-reverse;
  justify-content: flex-end;

  .wrap.is-pending {
    margin-left: auto;
  }

  .wrap.is-failed {
    display: flex;
    align-items: flex-end;
    margin-left: auto;
  }
}

.has-context-menu {
  background: transparent;
  .button--delete-message {
    visibility: visible;
  }
}

.context-menu {
  position: relative;
}

.interactive-buttons-container {
  display: flow;
}
</style>
