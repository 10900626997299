<template>
  <div class="context-menu">
    <woot-modal
      v-if="isCannedResponseModalOpen && showCannedResponseOption"
      :show.sync="isCannedResponseModalOpen"
      :on-close="hideCannedResponseModal"
    >
      <add-canned-modal
        :response-content="plainTextContent"
        :on-close="hideCannedResponseModal"
      />
    </woot-modal>
    <woot-button
      icon="chevron-down"
      color-scheme="white"
      variant="smooth"
      size="tiny-plus"
      @click="handleContextMenuClick"
    />
    <div
      v-if="isOpen && !isCannedResponseModalOpen"
      v-on-clickaway="handleContextMenuClick"
      class="dropdown-pane dropdown-pane--open"
      :class="`dropdown-pane--${menuPosition}`"
    >
      <woot-dropdown-menu>
        <woot-dropdown-item v-if="showDelete" style="display: none;">
          <woot-button
            variant="clear"
            color-scheme="alert"
            size="small"
            icon="delete"
            @click="handleDelete"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.DELETE') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="showReplyTo">
          <woot-button
            variant="clear"
            size="small"
            icon="arrow-reply"
            color-scheme="secondary"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.REPLY_TO') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="showCopy">
          <woot-button
            variant="clear"
            size="small"
            icon="clipboard"
            color-scheme="secondary"
            @click="handleCopy"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.COPY') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="showCannedResponseOption">
          <woot-button
            variant="clear"
            size="small"
            icon="comment-add"
            color-scheme="secondary"
            @click="showCannedResponseModal"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.CREATE_A_CANNED_RESPONSE') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="visibleTranslate">
          <woot-button
            variant="clear"
            size="small"
            icon="translate"
            color-scheme="secondary"
            @click="handleTranslate"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.TRANSLATE') }}
          </woot-button>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
  </div>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mixin as clickaway } from 'vue-clickaway';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';

import AddCannedModal from 'dashboard/routes/dashboard/settings/canned/AddCanned';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';

export default {
  components: {
    AddCannedModal,
    WootDropdownMenu,
    WootDropdownItem,
  },
  mixins: [alertMixin, clickaway, messageFormatterMixin],
  props: {
    messageContent: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
    showReplyTo: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    menuPosition: {
      type: String,
      default: 'left',
    },
    showCannedResponseOption: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isCannedResponseModalOpen: false,
      visibleTranslate: false,
    };
  },
  computed: {
    plainTextContent() {
      return this.getPlainText(this.messageContent);
    },
  },
  methods: {
    handleContextMenuClick() {
      this.$emit('toggle', !this.isOpen);
    },
    async handleCopy() {
      await copyTextToClipboard(this.plainTextContent);
      this.showAlert(this.$t('CONTACT_PANEL.COPY_SUCCESSFUL'));
      this.$emit('toggle', false);
    },
    handleDelete() {
      this.$emit('delete');
    },
    hideCannedResponseModal() {
      this.isCannedResponseModalOpen = false;
      this.$emit('toggle', false);
    },
    showCannedResponseModal() {
      this.$track(ACCOUNT_EVENTS.ADDED_TO_CANNED_RESPONSE);
      this.isCannedResponseModalOpen = true;
    },

    handleTranslate() {
      this.$emit('translate');
      this.handleContextMenuClick();
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-pane {
  box-shadow: none;
  bottom: 2.7rem;
  top: auto;
  left: 0;
  border: 1px solid var(--conversa2-neutral-100-color);
}
.dropdown-pane--left {
  display: inline-table;
}
.dropdown-pane--right {
  display: inline-table;
}
.button {
  &.clear {
    &.secondary {
      color: var(--conversa2-neutral-800-color);

      &:hover {
        color: var(--conversa2-neutral-800-color);
        background-color: var(--conversa2-neutral-50-color);
      }
    }
  }
}
</style>
