<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('AGENT_MGMT.ADD.TITLE')"
        :header-content="$t('AGENT_MGMT.ADD.DESC')"
      />

      <form class="row" @submit.prevent="addAgent()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentName.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.NAME.LABEL') }}
            <span class="required">*</span>
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentType.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.LABEL') }}
            <select v-model="agentType">
              <option v-for="role in roles" :key="role.name" :value="role.name">
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.ERROR') }}
            </span>
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentEmail.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.EMAIL.LABEL') }}
            <span class="required">*</span>
            <input
              v-model.trim="agentEmail"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER')"
              @input="$v.agentEmail.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <div class="medium-12 columns">
            <label :class="{ error: $v.agentPhone.$error }">
              <div class="label-container">
                <span class="label-text">
                  {{ $t('AGENT_MGMT.ADD.FORM.PHONE.LABEL') }}
                </span>
                <span
                  v-tooltip.left="$t('AGENT_MGMT.ADD.FORM.PHONE.HELP')"
                  class="label-text-icon"
                >
                  <fluent-icon
                    icon="info"
                    type="solid"
                    width="30"
                    height="28"
                    viewbox="0 0 30 28"
                  />
                </span>
              </div>
              <div class="">
                <input
                  v-model.trim="agentPhone"
                  type="text"
                  :placeholder="$t('AGENT_MGMT.ADD.FORM.PHONE.PLACEHOLDER')"
                  class="form-control"
                  @input="$v.agentPhone.$touch"
                />
              </div>
              <span v-if="$v.agentPhone.$error" class="message">
                {{ $t('AGENT_MGMT.ADD.FORM.PHONE.ERROR') }}
              </span>
            </label>
          </div>

          <div class="row">
            <div class="medium-12 columns">
              <label :class="{ error: $v.agentExtension.$error }">
                <div class="label-container">
                  <span class="label-text">
                    {{ $t('AGENT_MGMT.ADD.FORM.EXTENSION.LABEL') }}
                  </span>
                  <span
                    v-tooltip.left="$t('AGENT_MGMT.ADD.FORM.EXTENSION.HELP')"
                    class="label-text-icon"
                  >
                    <fluent-icon
                      icon="info"
                      type="solid"
                      width="30"
                      height="28"
                      viewbox="0 0 30 28"
                    />
                  </span>
                </div>
                <div class="">
                  <input
                    v-model.trim="agentExtension"
                    type="text"
                    :placeholder="
                      $t('AGENT_MGMT.ADD.FORM.EXTENSION.PLACEHOLDER')
                    "
                    class="form-control"
                    @input="handleExtensionInput"
                  />
                </div>
                <span
                  v-if="$v.agentExtension.$error && $v.agentExtension.maxLength"
                  class="message"
                >
                  {{ $t('AGENT_MGMT.ADD.FORM.EXTENSION.ERROR') }}
                </span>
                <span v-else-if="!$v.agentExtension.maxLength" class="message">
                  {{ $t('AGENT_MGMT.ADD.FORM.EXTENSION.TOO_LONG') }}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.agentEmail.$invalid ||
                  $v.agentName.$invalid ||
                  $v.agentPhone.$invalid ||
                  ($v.agentExtension.$invalid && isEnabledIntertelUCChannel) ||
                  uiFlags.isCreating
              "
              :button-text="$t('AGENT_MGMT.ADD.FORM.SUBMIT')"
              :loading="uiFlags.isCreating"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('AGENT_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import {
  isPhoneE164OrEmpty,
  isExtensionValid,
} from 'shared/helpers/Validators';
import FluentIcon from 'shared/components/FluentIcon/Index.vue';

export default {
  components: { FluentIcon },
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    enabledFeatures: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      agentName: '',
      agentEmail: '',
      agentType: 'agent',
      agentPhone: '',
      agentExtension: '',
      vertical: 'bottom',
      horizontal: 'center',
      roles: [
        {
          name: 'administrator',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
        {
          name: 'agent',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
      ],
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      currentUser: 'getCurrentUser',
    }),
    /**
     * Retorna si esta habilitado el feature flag de canal de Intertel UC para el cliente
     */
    isEnabledIntertelUCChannel() {
      if (Object.keys(this.enabledFeatures).length === 0) {
        return false;
      }

      return this.enabledFeatures.channel_intertel_uc;
    },
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentEmail: {
      required,
      email,
    },
    agentType: {
      required,
    },
    agentPhone: {
      isPhoneE164OrEmpty,
    },
    agentExtension: {
      maxLength: maxLength(20),
      isExtensionValid,
    },
  },

  methods: {
    // Filtrar la entrada para bloquear la letra "ñ" (insensible a mayúsculas/minúsculas)
    handleExtensionInput() {
      this.agentExtension = this.agentExtension.replace(/ñ/gi, '');
      this.$v.agentExtension.$touch();
    },
    /**
     * Maneja la entrada de números de teléfono del agente.
     *
     * @function
     * @name handlePhoneInput
     * @returns {void}
     */
    handlePhoneInput() {
      // Elimina caracteres no numéricos del número de teléfono del agente.
      this.agentPhone = this.agentPhone.replace(/[^0-9]/g, '');

      // Reemplaza todas las ocurrencias de acento grave ('`') o acento agudo ('´') con una cadena vacía.
      this.agentPhone = this.agentPhone.replace(/[`´]/g, '');

      // Marca el campo de entrada del número de teléfono como "tocado" para activar las validaciones.
      this.$v.agentPhone.$touch();
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async addAgent() {
      try {
        await this.$store.dispatch('agents/create', {
          name: this.agentName,
          email: this.agentEmail,
          role: this.agentType,
          phone_number: this.agentPhone,
          phone_extension: this.agentExtension,
        });
        this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const {
          response: { data: { error: errorResponse = '' } = {} } = {},
        } = error;
        let errorMessage = '';
        if (error.response.status === 422) {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.EXIST_MESSAGE');
        } else {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE');
        }
        this.showAlert(errorResponse || errorMessage);
      }
    },
  },
};
</script>
<style scoped>
.label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
}

.label-text {
  display: flex;
  align-items: center;
}

.label-text-icon {
  height: 28px;
}

.required {
  margin-left: 4px;
  color: #de1e27;
}
</style>
